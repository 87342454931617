import { FilterState } from '../../../../types/filters';
import {
  LightSponsorshipApplication,
  SponsorshipApplicationStatus,
} from '../../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../..';
import { getFiltersQueryParams, getOrderingQueryParams, getPaginationQueryParams } from '../../pagination';
import { PaginatedResponse } from '../../types';

import { AxiosResponse } from 'axios';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';

type ViewMode = 'list' | 'kanban';

export type ListSponsorshipApplicationsResponse = PaginatedResponse<LightSponsorshipApplication>;
export type StatusRecordGroup = { records: LightSponsorshipApplication[]; count: number };
export type KanbanSponsorshipApplicationsResponse = Record<SponsorshipApplicationStatus, StatusRecordGroup>;

type GetSponsorshipApplicationsResponse = ListSponsorshipApplicationsResponse | KanbanSponsorshipApplicationsResponse;

type GetPartnerSponsorshipApplicationsOptions = {
  searchTerms?: string[];
  viewMode?: ViewMode;
  pagination?: MRT_PaginationState;
  filters?: FilterState[];
  sorting?: MRT_SortingState;
};

const getSponsorshipApplications = async ({
  searchTerms = [],
  viewMode = 'list',
  pagination,
  filters,
  sorting,
}: GetPartnerSponsorshipApplicationsOptions): Promise<AxiosResponse<GetSponsorshipApplicationsResponse>> => {
  /**
   * Backend request to get Sponsorship Applications using a list of search terms.
   */

  // Relative URL
  const relativeUrl = '/sponsorship-applications';

  // Query parameters
  let paginationOptions = {};

  if (pagination) {
    paginationOptions = getPaginationQueryParams(pagination);
  }

  const orderingOptions = getOrderingQueryParams(sorting);
  const filterOptions = getFiltersQueryParams(filters);

  const queryParams = new URLSearchParams({
    ...(paginationOptions || {}),
    ...orderingOptions,
    ...filterOptions,
    search: searchTerms.join(','),
    'view-mode': viewMode,
  });

  // Encode Partner name to account for spaces and reduce transformations across interfaces
  return await backendAuthenticatedAxios.get<GetSponsorshipApplicationsResponse>(`${relativeUrl}?${queryParams.toString()}`)
    .catch(() => {
      throw new Error('No Sponsorship Applications found');
    });
};

export default getSponsorshipApplications;
